import { IconProps } from '@/utilities/types/Icon';

const Users = (props: IconProps) => {
  const { height = 16, width = 16 } = props;
  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 22 22"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4677 8.59228C12.6857 8.02447 12.8051 7.40783 12.8051 6.76329C12.8051 6.57842 12.7953 6.39584 12.7761 6.21605C13.3204 5.26245 14.3468 4.61961 15.5233 4.61961C17.269 4.61961 18.6841 6.03476 18.6841 7.78044C18.6841 9.52612 17.269 10.9413 15.5233 10.9413C14.0583 10.9413 12.8261 9.94457 12.4677 8.59228ZM15.5233 6.42433C14.7744 6.42433 14.1672 7.03148 14.1672 7.78044C14.1672 8.5294 14.7744 9.13655 15.5233 9.13655C16.2723 9.13655 16.8794 8.5294 16.8794 7.78044C16.8794 7.03148 16.2723 6.42433 15.5233 6.42433Z"
        fill="currentColor"
      />
      <path
        d="M14.9942 18.3863H19.391C19.8685 18.3863 20.3251 18.0941 20.6187 17.5769C20.9268 17.0342 21.0323 16.2726 20.8005 15.5252C20.5254 14.6385 20.0273 13.6246 19.1405 12.8399C18.2572 12.0584 17.0259 11.5371 15.3181 11.5371C14.1869 11.5371 13.2648 11.7658 12.5144 12.1473C12.6128 12.2222 12.7087 12.2995 12.8019 12.3791C13.2223 12.738 13.5767 13.1311 13.8751 13.5379C14.2712 13.4158 14.7454 13.3418 15.3181 13.3418C16.6268 13.3418 17.4211 13.7283 17.9446 14.1915C18.5059 14.6882 18.8632 15.3713 19.0768 16.0599C19.1185 16.1943 19.1287 16.327 19.1167 16.4445C19.1112 16.4979 19.1017 16.5437 19.0908 16.5816H15.198C15.2764 17.2147 15.1987 17.8379 14.9942 18.3863Z"
        fill="currentColor"
      />
      <path
        d="M7.69988 2.56067C5.37884 2.56067 3.49726 4.44225 3.49726 6.76329C3.49726 9.08433 5.37884 10.9659 7.69988 10.9659C10.0209 10.9659 11.9025 9.08433 11.9025 6.76329C11.9025 4.44225 10.0209 2.56067 7.69988 2.56067ZM5.30198 6.76329C5.30198 5.43896 6.37556 4.36539 7.69988 4.36539C9.02421 4.36539 10.0978 5.43896 10.0978 6.76329C10.0978 8.08762 9.02421 9.1612 7.69988 9.1612C6.37556 9.1612 5.30198 8.08762 5.30198 6.76329Z"
        fill="currentColor"
      />
      <path
        d="M7.69999 11.5807C5.68225 11.5807 4.22745 12.1747 3.18387 13.0654C2.13612 13.9597 1.54763 15.1151 1.22265 16.1256C0.948713 16.9773 1.07339 17.8453 1.43735 18.4638C1.78428 19.0532 2.3237 19.3862 2.88786 19.3862H12.5121C13.0762 19.3862 13.6156 19.0532 13.9626 18.4638C14.3265 17.8454 14.4512 16.9774 14.1773 16.1256C13.8523 15.1151 13.2638 13.9597 12.2161 13.0655C11.1725 12.1748 9.71773 11.5807 7.69999 11.5807ZM4.35548 14.4382C5.03767 13.8559 6.06382 13.3854 7.69999 13.3854C9.33616 13.3854 10.3623 13.8559 11.0445 14.4382C11.7607 15.0495 12.2007 15.8743 12.4592 16.6781C12.5795 17.0522 12.5035 17.3848 12.4072 17.5484C12.3996 17.5613 12.3925 17.5723 12.386 17.5815H3.01388C3.00743 17.5723 3.0003 17.5613 2.99269 17.5484C2.89643 17.3848 2.82038 17.0522 2.9407 16.6781C3.19922 15.8743 3.63923 15.0495 4.35548 14.4382Z"
        fill="currentColor"
      />
      <path
        d="M7.69988 4.36539C6.37556 4.36539 5.30198 5.43896 5.30198 6.76329C5.30198 8.08762 6.37556 9.1612 7.69988 9.1612C9.02421 9.1612 10.0978 8.08762 10.0978 6.76329C10.0978 5.43896 9.02421 4.36539 7.69988 4.36539ZM3.49726 6.76329C3.49726 4.44225 5.37884 2.56067 7.69988 2.56067C10.0209 2.56067 11.9025 4.44225 11.9025 6.76329C11.9025 9.08433 10.0209 10.9659 7.69988 10.9659C5.37884 10.9659 3.49726 9.08433 3.49726 6.76329Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Users;
